<template>
  <div class="home-page">
    <div class="panel-center-flex">
      <div class="category-displayer most-popular-panel">
        <div class="category-displayer-header">
          Most popular
        </div>
        <i class="fas fa-trophy"></i>
        <router-link to="/rankings" class="category-button view-rankings">
          See club rankings
        </router-link>
      </div>
      <div class="category-displayer">
        <div class="category-displayer-header">
          Stay active
        </div>
        <div class="category-four-row">
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Club Sports')">Club Sports</button>
            <i class="fas fa-biking"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Sports')">Other Sports</button>
            <i class="fas fa-running"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Dance')">Dance</button>
            <i class="fas fa-users"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Fitness')">Fitness</button>
            <i class="fas fa-heartbeat"></i>
          </div>
        </div>
      </div>
      <div class="category-displayer">
        <div class="category-displayer-header">
          Boost your Career
        </div>
        <div class="category-four-row">
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Business')">Business</button>
            <i class="fas fa-briefcase"></i>
          </div>
          <div class="category-straight">
            
            <button class="category-button" @click="categoryClick('Engineering')">Engineering</button><i class="fas fa-tools"></i>
          </div>
          <div class="category-straight">
            
            <button class="category-button" @click="categoryClick('Technology')">Technology</button><i class="fab fa-python"></i>
          </div>
          <div class="category-straight">
            
            <button class="category-button" @click="categoryClick('Medical')">Medical</button><i class="fas fa-virus"></i>
          </div>
        </div>
      </div>
      <div class="category-displayer">
        <div class="category-displayer-header">
          Excel in School
        </div>
        <div class="category-four-row">
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Career Planning')">Career Planning</button>
            <i class="fas fa-project-diagram"></i>
          </div>
          <div class="category-straight"> 
            <button class="category-button" @click="categoryClick('Academic')">Academic</button>
            <i class="fas fa-user-graduate"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Honor Societies')">Honor Societies</button>
            <i class="fas fa-award"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Educational')">Educational</button>
            <i class="fas fa-book-reader"></i>
          </div> 
        </div>
      </div>
      <div class="category-displayer">
        <div class="category-displayer-header">
          Help Others
        </div>
        <div class="category-four-row">
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Community Service')">Community Service</button>
            <i class="fas fa-blind"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Environmental')">Environment</button>
            <i class="fas fa-leaf"></i> 
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Student Government')">Student Government</button>
            <i class="fas fa-flag-usa"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Social Activism')">Activism</button>
            <i class="fas fa-fist-raised"></i>
          </div>
        </div>
      </div>
      <div class="category-displayer">
        <div class="category-displayer-header">
          Explore Cultures
        </div>
        <div class="category-four-row">
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('African American')">African American</button>
            <i class="fas fa-globe-africa"></i> 
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Asian')">Asian</button>
            <i class="fas fa-globe-asia"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Latino/Latina')">Latino/Latina</button>
            <i class="fas fa-globe-americas"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Cultural/Ethnic')">More</button>
            <i class="far fa-flag"></i>  
          </div>
        </div>
      </div>
      <div class="category-displayer">
        <div class="category-displayer-header">
          Meet new People
        </div>
        <div class="category-four-row">
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Greek Life - Fraternities')">Fraternities</button>
            <i class="fas fa-fire-extinguisher"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Greek Life - Sororities')">Sororities</button>
            <i class="fas fa-icons"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Recreation')">Recreation</button>
            <i class="fas fa-hiking"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Social')">Social &amp; Networking</button>
            <i class="fas fa-people-arrows"></i> 
          </div>
        </div>
      </div>
      <div class="category-displayer">
        <div class="category-displayer-header">
          Connect with Religion
        </div>
        <div class="category-four-row">
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Religious - Christian')">Christian</button>
            <i class="fas fa-bible"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Religious - Jewish')">Jewish</button>
            <i class="fas fa-torah"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Religious - Muslim')">Muslim</button>
            <i class="fas fa-quran"></i>
          </div>
          <div class="category-straight">
            <button class="category-button" @click="categoryClick('Religious')">More</button>
            <i class="fas fa-yin-yang"></i> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['fullClubList'],
  emits: ['categoryClick'],
  data(){
    return{

    }
  },
  methods:{
    categoryClick(c){
      this.$emit('categoryClick', c);
    },
  }
}
</script>

<style scoped>
.home-page{
  padding: 60px 20px;
  background: #eaeded;
}
.panel-center-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -moz-column-gap: 20px;
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
  flex: auto;
  max-width: 1460px;
}
.category-displayer{
  width: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 4px;
}
.category-displayer-header{
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
}
.category-four-row{
  display: flex;
  flex-wrap: wrap;
  min-width: 294px;
  justify-content: space-around;
  gap: 30px;
  width: 27vw;
  max-width: 336px;

}
.category-straight{
  display: flex;
  flex-direction: column-reverse;
  width: 130px;
}
.far, .fas, .fab{
  color: #7dadfb;
  font-size: 90px;
  transition: .5s all;
}
.category-button{
  margin: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background: white;
  color: black;
  margin-top: 6px;
  border-radius: 8px;
  border: 1px solid gray;
  box-shadow: rgb(100 100 111 / 20%) 0px 0px 29px 0px;
  transition: .3s all;
}
.category-button:hover{
  background: lightgray;
}
.category-button:hover + i{
  transform: translateX(20px);
  /*transform: rotate(20deg);*/
  opacity: .7;
}
.view-rankings{
  text-decoration: none;
  padding: 20px 76px;
  margin-top: 30px;
}
.most-popular-panel{
  justify-content: space-between;
}
.fa-trophy{
  color: var(--primary-color);
}
</style>